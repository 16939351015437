<template>
  <!-- 版权 -->
  <div class="footer-copyright d-flex justify-content-center flex-column align-items-center">
    <div class="text-white">中壬银兴信息技术有限公司版权所有 CopyRight © 2019,
      ZhongRenYinXing Information Technology Co.,Ltd All Rights Reserved
      <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备17123278号</a></div>
    <div class="text-white" style="margin-top: 2px">公司地址：深圳市福田区联合金融大厦A座14层</div>
  </div>
  <!-- 版权 end -->
</template>

<script>
  export default {
    name: "CopyRight"
  }
</script>

<style scoped>
  .footer-copyright {
    border-top: solid 1px #333333;
    height: 60px;
    font-size: 14px;
  }
</style>

<template>
  <div class="wrap d-flex flex-column" :style="bg_image">
    <common-title title="客户案例" english="Customer case"> </common-title>
    <div class="container-content d-flex justify-content-center align-items-center position-relative">
      <div class="content-item d-flex justify-content-end align-items-center" v-for="(item, index) in customerItems" :key="index">
        <div class="content-left" :class="moveShow ? 'slide-in-left' : ''">
          <h3 class="content-left-title text-center">{{ item.title }}</h3>
          <p class="content-left-txt">{{ item.txt }}</p>
          <div class="content-left-notice">{{ item.notice }}</div>
        </div>
        <div class="content-right">
          <img class="w-100" :src="item.src" alt="" />
        </div>
      </div>
      <div class="center-bg"></div>
    </div>
  </div>
</template>
<script>
import commonTitle from "@/components/index/common-title.vue";
export default {
  props: {
    moveShow: Boolean,
  },
  components: {
    commonTitle,
  },
  data() {
    return {
      bg_image: {
        // backgroundImage: "url(" + require("@/static/image/bg-hb.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "top top",
      },
      customerItems: [
        {
          src: require("@/static/image/anli-1.png"),
          title: "某商业保理公司",
          txt:
            "该保理公司借助中壬云建立覆盖信贷审核、流水反欺诈、授信策略以及贷后预警在内的全流程智能风控解决方案。通过中壬云系统录入客户财报、流水及纳税表，经过系统PVE智能算法运算，输出该客户在未来30-90天内现金流区间值、存货、应收、预售账款周转天数等信贷考核重要数据指标。通过企业综合评估报告，有效帮助该公司快速了解目标客户保理融资安全金额以及潜在的风险，大幅提升效率，降低审批过程中的业务风险和综合成本。",
          notice:
            "以SaaS化方式为保理业务提供全流程数字化能力，助其高效开展业务。",
        },
        // {
        //   src: require("@/static/image/anli-1.png"),
        //   title: "某商业保理公司",
        //   txt:
        //     "该商业保理公司计划将业务数字化升级，借助中壬云债权评测系统，建立覆盖信贷审核、流水反欺诈、授信策略以及贷后预警在内的全流程智能风控解决方案。该保理公司通过中壬云系统录入客户财报、流水及纳税表，经过系统PVE智能算法运算，输出该客户在未来30天-90天内现金流区间值，存货、应收、预售账款周转天数，息税前营业利润等信贷考核重要数据指标。以这些数据组成的企业信贷价值报告，有效帮助该保理公司快速了解目标客户保理融资安全金额以及潜在的风险，大幅度提升业务效率。降低审批过程中的业务风险和综合成本。",
        //   notice:
        //     "以SaaS化方式为保理业务提供全流程数字化能力，助其高效开展业务。",
        // },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #f6f3ff;
  .center-bg {
    position: absolute;
    z-index: 0;
    width: 200px;
    height: 300px;
    // padding-right: 100px ;
    background-color: #f2eeff;
    // filter: blur(20px)
  }
  .container-content {
    top: 320px;
    .content-item {
      position: absolute;
      z-index: 888;
      // left: 0;
      .content-left {
        width: 50%;
        margin-left: 70px;
        padding-right: 20px;
        .content-left-title {
          color: #11163c;
          font-size: 24px;
          text-align: center;
          padding-bottom: 10px;
          // font-weight: bold;
        }
        .content-left-txt {
          text-indent: 2em;
          color: #808cec;
          line-height: 2;
          font-size: 18px;
          text-align: justify;
        }
        .content-left-notice {
          color: #ff4484;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .content-right {
        img {
          //   width: 50%;
        }
      }
    }
  }
}
.slide-in-left {
  animation: slide-in-left 1s 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
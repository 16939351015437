<template>
  <div class="content" :style="listHight" ref="content">
    <ul class="list" ref="list">
      <li :style="listHight">
        <first-banner :countShow="index === 0"></first-banner>
      </li>
      <!-- 我们的优势 -->
      <li :style="listHight">
        <two-advantage :moveShow="index === 1"> </two-advantage>
      </li>
      <!-- 应用场景 -->
      <li :style="listHight">
        <there-scene :moveShow="index === 2"></there-scene>
      </li>
      <!-- 客户案例 -->
      <li :style="listHight">
        <four-customer :moveShow="index === 3"></four-customer>
      </li>
      <!-- 合作伙伴 -->
      <li :style="listHight">
        <five-honor :moveShow="index === 4"></five-honor>
      </li>
    </ul>
  </div>
</template>
<script>
import firstBanner from "@/components/index/first-banner.vue";
import twoAdvantage from "@/components/index/two-advantage.vue";
import thereScene from "@/components/index/there-scene.vue";
import fourCustomer from "@/components/index/four-customer.vue";
import fiveHonor from "@/components/index/five-honor.vue";
import minScene from "@/minixs/screen.js";
export default {
  components: {
    firstBanner,
    twoAdvantage,
    thereScene,
    fourCustomer,
    fiveHonor,
  },
  mixins: [minScene],
  mounted() {
  //  this.$loading.show() // 显示loading
  //  setTimeout(()=>{
  //      this.$loading.hide() //隐藏loading
  //  },2000)
  },
};
</script>

<style lang="less">
ul,
li {
  margin: 0px;
  padding: 0px;
}
.content {
  background: #11163c;
  position: relative;
  overflow: hidden;
  width: 100%;
  .list {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: #11163c;
    transition: 1s top;
    li {
      list-style: none;
      position: relative;
      background-position: 50% 50% !important;
    }
  }
}
</style>

<template>
  <div class="wrap d-flex align-items-center flex-column">
    <common-title title="我们的优势" english="Our advantage"> </common-title>
    <div class="container d-flex justify-content-center align-items-center" style="margin-top: 50px;">
      <div class="container-content d-flex flex-wrap" ref="containerContent">
        <div
          class="adv-item d-flex justify-content-center align-items-center"
          :style="!moveShow ? slideIn(index) : ''"
          v-for="(item, index) in advItems"
          :key="index"
        >
          <div class="adv-item-left w-25">
            <img class="" :src="item.src" />
          </div>
          <div class="adv-item-right flex-fill">
            <div class="title">{{ item.title }}</div>
            <div class="txt">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonTitle from "@/components/index/common-title.vue";
export default {
  props: {
    moveShow: {
      type: Boolean,
    },
  },
  components: {
    commonTitle,
  },

  methods: {
    slideIn(index) {
      if ((index + 1) / 2 === 0) {
        return " animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;";
      } else {
        return " animation: slide-in-right 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;";
      }
    },
  },
  data() {
    return {
      advItems: [
        {
          src: require("@/static/image/ys-1.png"),
          title: "3D风控深度链接",
          text:
            "区别于业内横向迭代的风控产品，中壬云更注重不同金融业务场景底层逻辑，通过多维度信息进行产品迭代，深度链接风控各个维度。",
        },
        {
          src: require("@/static/image/ys-2.png"),
          title: "深耕流水反欺诈",
          text:
            "通过数据勾兑比例取值、交叉核验各项数据、自动剔除关联交易虚假流水等来鉴别营收、成本、周转、利润数据真伪，帮助金融机构有效识别流水欺诈行为。",
        },
        {
          src: require("@/static/image/ys-3.png"),
          title: "原创型算法专利",
          text:
            "更适合本土中小微企业信贷审核，模型随市场周期变动，迭代修正底层算力参数，提升企业贷款资质审核效率及准确率。",
        },
        {
          src: require("@/static/image/ys-4.png"),
          title: "多维度数据取值",
          text:
            "不依赖传统单一的工商、司法、纳税数据，而是基于财报、流水、纳税数值勾兑比例取值，准确判断中小微企业经营情况。",
        },
        {
          src: require("@/static/image/ys-5.png"),
          title: "操作安全便捷",
          text:
            "全程线上操作，系统自动测算评估，避免人为出错和漏洞，快速获取审核结果。",
        },
        {
          src: require("@/static/image/ys-6.png"),
          title: "高效省时省力",
          text:
            "相比传统人工考察、繁杂的数据分析流程，智能数据分析更高效，为信贷策略制定提供科学依据。",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.wrap {
  background-color: #f6f3ff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  .container-content {
    // margin-right: -30px;
    .adv-item {
      cursor: pointer;
      &:nth-child(odd) {
       animation: slide-in-left 1s 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }
      &:nth-child(even) {
      animation: slide-in-right 1s 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }
      width: 48%;
      margin-right: 20px;
      margin-bottom: 50px;
      &:hover img {
        animation: wobble-hor-bottom 0.8s both;
      }
      &:nth-child(even) {
        margin-right: 0px;
      }
      .adv-item-left {
        img {
          width: 130px;
        }
      }
      .adv-item-right {
        margin-left: 30px;
        margin-right: 30px;
        .title {
          font-size: 22px;
          color: #808cec;
        }
        .txt {
          padding-top: 5px;
          color: #6f76b0;
           font-size: 16px;
        }
      }
    }
  }
}

@keyframes wobble-hor-bottom {
  0%,
  100% {
      transform:scale(1) ;
    transform-origin: bottom bottom;
  }
  50%{
     transform:scale(1.05) ;
  }
 
}
// 左进入
@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
// 右进入
@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
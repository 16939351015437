<template>
  <div class="wrap d-flex flex-column">
    <common-title title="应用场景" english="Application scenario">
    </common-title>
    <div class="container mt-5 d-flex justify-content-center align-items-center">
      <div class="container-content d-flex flex-wrap">
        <div
          class="scene-item"
          v-for="(item, index) in sceneItems"
          :key="index"
          :class="moveShow?'scale-in-center':''"
        >
          <div class="scene-bright">
            <img :src="item.src" alt="" />
            <div class="scene-title scene-title-hidden">
              {{ item.title }}
            </div>
          </div>
          <div
            class="scene-mask d-flex flex-column justify-content-center align-items-center"
          >
            <div class="scene-title">
              {{ item.title }}
            </div>
            <div class="scene-description">
              {{ item.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonTitle from "@/components/index/common-title.vue";
export default {
  components: {
    commonTitle,
  },
  props: {
    moveShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      sceneItems: [
        {
          src: require("@/static/image/scene-1.png"),
          title: "贷后监控",
          description:
            "对已放出的贷款进行有效监控，帮助机构快速对风险做出预警，降低人力监控成本。",
        },
        {
          src: require("@/static/image/scene-2.png"),
          title: "企业定额",
          description: "帮助机构快速对小微企业进行风险价值评估，确定授信额度。",
        },
        {
          src: require("@/static/image/scene-3.png"),
          title: "业务员筛选",
          description:
            "帮助金融从业业务员，在提交进件前进行客户初筛，减少资质不足或客户刻意隐瞒导致的时间资源浪费。",
        },
        {
          src: require("@/static/image/scene-4.png"),
          title: "贷前审核",
          description:
            "针对企业贷款申请，帮助金融机构评估企业风险，加快信贷决策。",
        },
        {
          src: require("@/static/image/scene-5.png"),
          title: "资源投放决策",
          description:
            "帮助政府部门、园区的政府产业扶持补助、园区入驻等资源决策提供支持。",
        },
        {
          src: require("@/static/image/scene-6.png"),
          title: "供应链金融",
          description:
            "基于企业上下游、交易稳定性及关联关系挖掘，输出风险策略。",
        },
      ],
    };
  },
  methods: {
  },
};
</script>
<style lang="less" scoped>
.wrap {
  background-color: #11163c;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  .container-content {
    .scene-item {
      cursor: pointer;
      width: 30%;
      position: relative;
      overflow: hidden;
      margin-right: 30px;
      margin-bottom: 30px;
      :nth-child(3n) {
        margin-right: 0px;
      }
      .scene-title {
        font-size: 24px;
        color: white;
      }
      .scene-bright {
        position: relative;
        img {
          width: 100%;
          height: 100%;
          transition: 1s transform;
        }
        .scene-title {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        .scene-title-hidden {
          opacity: 1;
          transition: 1s opacity;
        }
      }
      .scene-mask {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: #000;
        opacity: 0;
        transition: 1s opacity;
        .scene-description {
          padding: 0 20px;
          color: #fff;
          font-size: 16px;
          line-height: 2;
        }
      }
      &:hover .scene-mask {
        opacity: 0.8;
      }
      &:hover img {
        overflow: hidden;
        transform: rotate(30deg) scale(1.5);
      }
      &:hover .scene-title-hidden {
        opacity: 0;
      }
    }
  }
}
.scale-in-center {
	animation: scale-in-center 1s 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@keyframes scale-in-center {
  0% {
    transform: scale(0.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
<template>
  <div class="wrap d-flex flex-column" :style="bg_image">
    <common-title title="荣誉认可" english="Honor recognition" style="padding: 20px 0"></common-title>
    <div class="container">
      <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item" v-for="(items,index) in honorItems" :key="index" :class="{'active':index === 0}">
            <div
              class="container-content d-flex justify-content-center align-items-center flex-wrap"
            >
              <div
                class="content-item d-flex justify-content-center align-items-center flex-column"
                v-for="(item, index) in items"
                :key="index"
                v-viewer
              >
                <img :src="item.pictureUrl" class="w-100 pt-3 img-render" alt="" style="object-fit: cover;height: 210px" />

                <div class="text-white pt-3">
                  {{ item.pictureName }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" style="background: transparent;border: none" type="button"
                data-target="#carouselExampleControls" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </button>
        <button class="carousel-control-next" style="background: transparent;border: none" type="button"
                data-target="#carouselExampleControls" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </button>
      </div>
      <common-title title="合作伙伴" english="Honor recognition">
      </common-title>
      <div class="container-content-2 d-flex flex-wrap">
        <div
          class="content2-item"
          v-for="(item, index) in partnerItem"
          :key="index"
          :class="moveShow ? 'fade-in-bottom  ' : ''"
        >
          <a class="href-item" :href="item.url" :target="item.way">
            <img :src="item.src" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import "viewerjs/dist/viewer.css";
  import Viewer from "v-viewer";
  import Vue from "vue";
  import baseUrl from "@/utils/request.js";
  Vue.use(Viewer);
  import commonTitle from "@/components/index/common-title.vue";

  export default {
    props: {
      moveShow: Boolean,
    },
    components: {
      commonTitle,
      Viewer,
    },
    mounted() {
      Viewer.setDefaults({});
      this.getList()
    },

    computed:{
      honorItems() {
        let size = 3
        let arr = [];
        let total = Math.ceil(this.certificateList.length / size);
        for (let page = 1; page <= total; page++) {
          arr.push(this.certificateList.slice((page - 1) * size, page * size));
        }
        return arr;
      }
    },

    data() {
      return {
        image: ["@/static/image/hz-1.png"],
        bg_image: {
          backgroundImage: "url(" + require("@/static/image/bg-ry.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        },
        partnerItem: [
          {
            url: "",
            way: "_blank",
            src: require("@/static/image/hz-1.png"),
          },
          {
            url: "",
            way: "_blank",
            src: require("@/static/image/hz-2.png"),
          },
          {
            url: "",
            way: "_blank",
            src: require("@/static/image/hz-3.png"),
          },
          {
            url: "",
            way: "_blank",
            src: require("@/static/image/hz-4.png"),
          },
          {
            url: "",
            way: "_blank",
            src: require("@/static/image/hz-5.png"),
          },
          {
            url: "",
            way: "_blank",
            src: require("@/static/image/hz-6.png"),
          },
          {
            url: "",
            way: "_blank",
            src: require("@/static/image/hz-7.png"),
          },
          {
            url: "",
            way: "_blank",
            src: require("@/static/image/hz-8.png"),
          },
          {
            url: "",
            way: "_blank",
            src: require("@/static/image/hz-9.png"),
          },
          {
            url: "",
            way: "_blank",
            src: require("@/static/image/hz-10.png"),
          },
          {
            url: "",
            way: "_blank",
            src: require("@/static/image/hz-11.png"),
          },
          {
            url: "",
            way: "_blank",
            src: require("@/static/image/hz-12.png"),
          },
        ],
        certificateList: [
          {
            src: require("@/static/image/honor_14.jpg"),
            title: "专精特新企业证书",
          },
          {
            src: require("@/static/image/honor_09.jpg"),
            title: "中科院智能文档处理联合实验室",
          },
          {
            src: require("@/static/image/honor_10.jpg"),
            title: "科技创新奖",
          },
          {
            src: require("@/static/image/honor_11.jpg"),
            title: "卓越贡献奖",
          },
          {
            src: require("@/static/image/honor_12.jpg"),
            title: "先进企业证书",
          },
          {
            src: require("@/static/image/honor_13.jpg"),
            title: "热心公益奖",
          },
          {
            src: require("@/static/image/honor_01.jpg"),
            title: "中国数字科技年度50强",
          },
          {
            src: require("@/static/image/honor_02.jpg"),
            title: "2022年金融科技影响力品牌",
          },
          {
            src: require("@/static/image/honor_03.jpg"),
            title: "2022年金融科技影响力品牌",
          },
          {
            src: require("@/static/image/honor_04.jpg"),
            title: "SAAS+保理”模式杰出企业",
          },
          {
            src: require("@/static/image/honor_05.jpg"),
            title: "高新技术企业证书",
          },
          {
            src: require("@/static/image/honor_06.jpg"),
            title: "杰出服务机构",
          },
          {
            src: require("@/static/image/honor_07.jpg"),
            title: "智能风控信贷行业领军企业",
          },
          {
            src: require("@/static/image/honor_08.jpg"),
            title: "科技进步企业",
          }
        ]
      };
  },

  methods: {
    async getList() {
      let res = await this.$axios.get(baseUrl.BASE_URL + "/system/picture-manage/show", { params: { displayArea: "Honor_ZRYX", page: 1, size: 100 } })
      this.certificateList= res.data.data
      // let data = {};
        // data.page = this.page;
        // data.size = this.size;
        // data.newsTypeCodes = ["DC002"];
        // this.$axios
        //   .post(`${baseUrl.BASE_URL}/document/news/list`, data)
        //   .then((res) => {
        //     this.$loading.show();
        //     // console.log("获取成功", res);
        //     this.content = res.data.data;
        //     this.total = res.data.total;
        //     this.allPage = Math.ceil(this.total / this.size);
        //     this.$loading.hide();
        //   })
        //   .catch((err) => {
        //     this.$loading.hide();
        //     console.log("获取失败", err);
        //   });
      },
    }
  };
</script>

<style lang="less" scoped>
  .wrap {
    background-color: #11163c;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    .container-content {
      width: 100%;

      .content-item {
        width: 30%;
        height: 280px;

        background-color: rgba(255, 255, 255, 0.1);
        padding: 20px;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(255, 255, 255, 0.3);
        margin-right: 30px;

        cursor: pointer;
      }
    }

    .container-content-2 {
      .content2-item {
        margin-right: 10px;

        .href-item {
          img {
            padding-bottom: 10px !important;
          }
        }
      }
    }
  }

  .fade-in-bottom {
    animation: fade-in-bottom 1.5s 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }

  @keyframes fade-in-bottom {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .img-render {
    image-rendering:-moz-crisp-edges;
    image-rendering:-o-crisp-edges;
    image-rendering:-webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode:nearest-neighbor;
  }
</style>

<template>
  <div class="wrap h-100 position-relative" :style="bg_image">
    <div class="banner w-100">
      <img class="w-100 banner-img" src="@/static/image/banner-1.png" alt=""/>
    </div>
    <div class="banner-title text-center">
      <div class="title text-center">
        <div>无限探索数字科技服务边界</div>
        <div>为客户创造超出预期的价值</div>
      </div>
      <div class="english text-muted py-2">
        Infinitely explore the boundaries of digital technology services Create
        value beyond expectations for customers
      </div>

      <div class="text-center mt-5">
        <a
          href="https://www.cnskbb.com/"
          target="_blank"
          class="dy-btn border-0 rounded-pill border-dark small text-white bg-pink px-4 py-2"
        >
          免费试用
        </a>
      </div>
    </div>

    <div class="banner-data">
      <div class="banner-content d-flex justify-content-center container">
        <div
          class="banner-item w-25"
          v-for="(item, index) in bannerData"
          :key="index"
        >
          <div class="banner-value text-pink">
            <countTo
              ref="count"
              :startVal="startVal"
              :endVal="item.value ? item.value : 0"
              :duration="1500"
              :autoplay="true"
            ></countTo>
            <!-- {{ item.value }} -->
            <small class="banner-unit text-white">{{ item.unit }}</small>
          </div>
          <div class="banner-name text-white">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <copy-right style="position: absolute;bottom: 0;width: 100%"/>
  </div>
</template>
<script>
  import baseUrl from "@/utils/request.js";
  import countTo from "vue-count-to";
  import CopyRight from "./CopyRight";

  export default {
    props: {
      countShow: {
        type: Boolean,
      },
    },
    components: {
      CopyRight,
      countTo,
    },

    data() {
      return {
        startVal: 0,
        bannerData: [
          {
            value: "",
            name: "实时评测数据",
            unit: "次",
          },
          {
            value: 512,
            name: "平均计算",
            unit: "次",
          },
          {
            value: 3,
            name: "循环计算",
            unit: "次",
          },
          {
            value: 5,
            name: "评测结果",
            unit: "分钟",
          },
        ],

        bg_image: {
          backgroundImage: "url(" + require("@/static/image/banner-2.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        },
      };
    },
    watch: {
      countShow(newBool) {
        if (newBool) {
          this.clickCount();
        }
      },
    },
    mounted() {
      this.getData();
    },
    methods: {
      //实时数据测评
      getData() {
        this.$axios
          .get(`${baseUrl.BASE_URL}/home/pt-data`, {})
          .then((res) => {
            console.log("获取成功实时测试数据", res);
            let result = res.data.data;
            let realTime = result.evalCount;
            // parseInt(result.evalCount) + parseInt(res.fuzzyEvalCount);
            console.log("获取成功实时测试数据", realTime);

            this.$set(this.bannerData[0], `value`, realTime);
            // this.$set()
          })
          .catch((err) => {
            console.log("获取失败", err);
          });
      },

      // 切换 数字滚动效果
      clickCount() {
        let countLength = this.$refs.count.length;
        for (let i = 0; i < countLength; i++) {
          this.$refs.count[i].start();
          this.$refs.count[i].startVal = 0;
        }
      },
    },
  };
</script>
<style lang="less" scoped>
  .wrap {
    position: relative;

    .banner {
      position: absolute;
      left: 50%;
      top: 30%;
      transform: translate(-50%, -50%);

      .banner-img {
        animation: scale-up-center 25s infinite linear;
      }
    }

    .banner-title {
      position: absolute;
      left: 50%;
      top: 20%;
      transform: translate(-50%, -50%);
      padding-top: 200px;
      z-index: 999;

      .title {
        color: #808cec;
        //   font-weight: bold;
        font-size: 38px;
        letter-spacing: 10px;
      }

      .english {
        font-size: 20px;
        color: #eee;
        width: 600px;
      }

      .dy-btn:hover {
        -webkit-animation: blink-2 0.9s linear;
        animation: blink-2 0.9s linear;
        text-decoration: none !important;
      }
    }

    .banner-data {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 110px;
      text-align: center;

      .banner-content {
        .banner-item {
          .banner-value {
            font-size: 40px;
            font-weight: 600;

            .banner-unit {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  @-webkit-keyframes blink-2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes blink-2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
</style>

